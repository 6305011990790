import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { Input, TextArea } from "@start-base/react-form-elements";
import { useNavigate } from "react-router-dom";
import agency from "../agency.png";
// import loginlogo from "../logo-dark.png";
import loginlogo from "../logo-black.png";
import { message } from 'antd'
import Axios from "axios";
import { APIUrl } from "..";
import { Tooltip } from "react-tooltip";
import Cookies from "universal-cookie";
import ForgotPasswordDialog from "./ForgotPassword";
import ReactIntlTelInput from 'react-intl-tel-input-v2';
//import { LevelContext } from "../App";
import 'intl-tel-input/build/css/intlTelInput.css';
import PhoneInput from "react-phone-input-2";

export default function Login() {
  const [messageApi, contextHolder] = message.useMessage();
  const [rememberMeChecked, setRememberMeChecked] = useState(false)
  const cookies = new Cookies();
  var rememberMeDts = cookies.get("rememberMe");
  const [newNO, setnewNo] = useState('')
  // const [phoneNumber, setPhoneNumber] = useState('')
  // const [countryCode, setCountryCode] = useState()
  useEffect(() => {
    if (rememberMeDts) {
      if (rememberMeDts.split(",")[2].split(":")[1] == "true") {
        var email = rememberMeDts.split(",")[0].split(":")[1];
        var pwd = rememberMeDts.split(",")[1].split(":")[1];
        setRememberMeChecked(rememberMeDts.split(",")[2].split(":")[1])
        setInputs((prevState) => ({
          ...prevState,
          email: email,
          password: pwd,
        }));
      }
    } else {
      setRememberMeChecked(false)
    }
  }, [rememberMeDts]);

  //const {isapproveduser, setIsApprovedUser} = useContext(LevelContext)
  const navigate = useNavigate();

  const [inputs, setInputs] = useState({});

  const [showerror, setShowError] = useState("");
  const [showsignuperror, setShowSignUpError] = useState("");
  const [showphonevalid, setShowPhoneValid] = useState("");

  const [showsignin, setShowSignIn] = useState(true);
  const [showsignup, setShowSignUp] = useState(false);

  const newdata = [];
  newdata.email = inputs.email;
  newdata.password = inputs.password;
  newdata.rememberMe = inputs.rememberMe;

  newdata.fullname = inputs.fullname;
  newdata.signupemail = inputs.signupemail;
  newdata.signuppwd = inputs.signuppwd;
  newdata.phone = inputs.phone;
  newdata.companyname = inputs.companyname;
  newdata.message = inputs.message;

  const onChange = (e) => {
    setShowError("");
    setShowSignUpError("");
    if (e.target.name == "phone") {
      const result = e.target.value.replace(/\D/g, "");
      setShowPhoneValid(result);
    }

    const { name, value, type } = e.target;

    setInputs((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? !prevState[name] : value,
    }));
  };

  function ValidateLogin(email, password) {
    //e.preventDefault();
    //  localStorage.removeItem("UserDetails");
    //  localStorage.removeItem("RestaurantDetails");
    //localStorage.clear();

    if (email && password) {
      Axios.post(APIUrl + "VerifyLogin", {
        data: {
          signupInfo: {
            UserName: email,
            Password: password,
          },
        },
      })
        .then((response) => {
          if (response.data.status == "s") {
            if (rememberMeChecked) {
              var dts = "Email:" + email + "," + "Pwd:" + password + "," + "Rem:" + rememberMeChecked;
              cookies.set("rememberMe", dts, { maxAge: 1800 });
            } else cookies.remove("rememberMe");
            var UserDts = {
              ResellerId: response.data.data.UserId,
              IsApprovedUser: response.data.data.IsApproved,
              Token: response.data.data.Token,
              IsSuperAdmin: response.data.data.isSuperAdmin,
              UserName: response.data.data.UserName,
              Name: response.data.data.Name,
              Phone: response.data.data.Phone
            };
            console.log(response.data.data.Phone);
            localStorage.setItem("UserDetails", JSON.stringify(UserDts));
            if (response.data.data.IsApproved === true) {
              navigate("/reseller/restaurant/list");
            }
            else if (response.data.data.IsApproved === false) {
              var UId = response.data.data.UserId;
              navigate("/reseller/schedulecall", { state: { UId } });
            }
          } else if (response.data.status == "f")
            messageApi.open({
              type: 'warning',
              content: response.data.message
            })
        })
        .catch((error) => {
          messageApi.open({
            type: 'error',
            content: 'Enter a valid username & password'
          })
        });
    } else //alert("Enter username & password");
      messageApi.open({
        type: 'warning',
        content: 'Enter username & password'
      })
  }

  function SignUp(e) {
    e.preventDefault();
    newdata.fullname = inputs.fullname;
    newdata.signupemail = inputs.signupemail;
    newdata.signuppwd = inputs.signuppwd;
    newdata.phone = newNO;
    newdata.companyname = inputs.companyname;

    Axios.post(APIUrl + "Signup", {
      data: {
        signupInfo: {
          UserName: newdata.signupemail,
          Password: newdata.signuppwd,
          Name: newdata.fullname,
          Phone: newdata.phone,
          CompanyName: newdata.companyname,
          SignupMsg: newdata.message,
        },
      },
    })
      .then((response) => {
        if (response.data.status == "s") {
          var UId = response.data.data.UserId;
          ValidateLogin(newdata.signupemail, newdata.signuppwd)
          // navigate("/reseller/setup/rest_add_update");
          // setShowSignUpError(response.data.message + ". You can sign in now");
        } else if (response.data.status == "f") {
          // setShowSignUpError(response.data.message);
          messageApi.open({
            type: 'warning',
            content: response.data.message
          })
        }
      })
      .catch((error) => {
        // alert(error.response.data.message);
        messageApi.open({
          type: 'error',
          content: 'Enter correct details & try again !'
        })
      });
  }



  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div className="login-screen-main">
        <div className="divLeft">
          <div className="divLefttInner">
            <div className="divLefttInnerMain">
               {/* <div className="login-bg-img">
                <img src={agency} className="img-fluid"></img>
              </div>
              <h1>Restaurant Online Ordering Solutions</h1>
              <p>
                We have excellent restaurant online ordering softwares, you have
                your audience. Let’s conquer the market together.
              </p> */}
              
              <div class="benefits-pos-block">
                <div class="benefits-pos-text">
                  <h4>Take advantage of our innovative solutions for your restaurant clients.</h4>
                  <div class="accordion" id="accordionLogin">
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                          aria-expanded="false" aria-controls="collapseOne">Cloud-Based POS System</button>
                      </h2>
                      <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionLogin">
                        <div class="accordion-body">
                          <p>Experience the freedom and flexibility of our cloud-based point-of-sale system, revolutionizing 
                            restaurant operations with real-time data synchronization, 
                            an intuitive interface, and seamless accessibility from anywhere, 
                            empowering you to take control of your business with ease.</p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                          aria-expanded="false" aria-controls="collapseTwo">Self-Service Kiosks</button>
                      </h2>
                      <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionLogin">
                        <div class="accordion-body">
                          <p>With brand new modern state-of-the-art self-service kiosks, restaurants can ensure faster service, 
                            reduced wait times, decreased labor costs, and increased customer satisfaction.</p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree"
                          aria-expanded="false" aria-controls="collapseThree">Online Ordering</button>
                      </h2>
                      <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionLogin">
                        <div class="accordion-body">
                          <p>With their own online ordering platform, restaurants can empower customers to conveniently place orders from anywhere, 
                            on any device. Benefits include gaining valuable customer data for marketing, increasing order accuracy, 
                            and growing sales through easy upselling. With their own user-friendly ordering platform, 
                            restaurants do not have to worry about paying out high commissions to expensive third-party ordering platforms.</p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour"
                          aria-expanded="false" aria-controls="collapseFour">Contactless Ordering</button>
                      </h2>
                      <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionLogin">
                        <div class="accordion-body">
                          <p>Restaurants can ensure the safety of customers by offering a touch-free ordering solution that minimizes physical contact. 
                            With a secure and intuitive option for contactless ordering, restaurants gain the trust of their guests and improve 
                            confidence while maintaining operational efficiency.</p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive"
                          aria-expanded="false" aria-controls="collapseFive">Branded Mobile Apps</button>
                      </h2>
                      <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionLogin">
                        <div class="accordion-body">
                          <p>Restaurants can stand out in the digital landscape with custom-branded mobile apps for iOS and Android 
                            tailored to match their unique identity. This offers a seamless and engaging experience for customers to place 
                            orders on their phones, while enhancing brand visibility, boosting customer loyalty, and driving even more revenue.</p>
                        </div>
                      </div>
                    </div>                    
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix"
                          aria-expanded="false" aria-controls="collapseSix">Branded Websites</button>
                      </h2>
                      <div id="collapseSix" class="accordion-collapse collapse" data-bs-parent="#accordionLogin">
                        <div class="accordion-body">
                          <p>First impressions matter. With a beautiful and modern mobile friendly website, restaurants can showcase their brand 
                            with photos, stories, and much more to fully customize the guest experience. This is a great way to attract new visitors, 
                            drive online visibility, and provide a seamless way for customers to find the online menu to order easily with no hassle.</p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven"
                          aria-expanded="false" aria-controls="collapseSeven">Third-Party Integrations</button>
                      </h2>
                      <div id="collapseSeven" class="accordion-collapse collapse" data-bs-parent="#accordionLogin">
                        <div class="accordion-body">
                          <p>Restaurants can unlock their full potential with our extensive third-party integration options. 
                            With an effortless way to connect with popular third-party platforms, including payment gateways, delivery services, 
                            and more, restaurants can streamline operations and enhance efficiency while expanding ordering capabilities.</p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight"
                          aria-expanded="false" aria-controls="collapseEight">Marketing & Retention</button>
                      </h2>
                      <div id="collapseEight" class="accordion-collapse collapse" data-bs-parent="#accordionLogin">
                        <div class="accordion-body">
                          <p>With a comprehensive suite of marketing and retention tools, restaurants can amplify marketing efforts and cultivate customer 
                            loyalty with ease. Our system makes it easy to engage customers through targeted marketing campaigns, implement a loyalty 
                            program for online customers, and leverage data driven insights to optimize marketing strategies, ensuring sustainable growth 
                            and repeat business.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="divRight">
          {contextHolder}
          <div className="divRightInner">
            {showsignin && (
              <div id="divSignIn">
                <div className="login-logo-img">
                  <img src={loginlogo} className="img-fluid"></img>
                </div>
                <div className="login-head">
                  <h2>Sign In</h2>
                  <p>Please fill details below to continue</p>
                </div>
                <form //onSubmit={ValidateLogin}
                >
                  <Input
                    label="Email Address"
                    placeholder="Enter email address"
                    name="email"
                    value={inputs.email}
                    onChange={onChange}
                    required="true"
                  />
                  <Input
                    label="Password"
                    placeholder="Enter password"
                    name="password"
                    value={inputs.password}
                    onChange={onChange}
                    required="true"
                    type="password"
                  />

                  <div className="remember-forgot-main">
                    <label>
                      <input
                        checked={rememberMeChecked}
                        name="rememberMe"
                        onChange={(e) => setRememberMeChecked(e.target.checked)}
                        type="checkbox"
                      />
                      Remember me
                    </label>

                    <a id="aForgotPass" onClick={handleClickOpen} href="#">
                      Forgot Password?
                    </a>
                    <ForgotPasswordDialog open={open} handleClose={handleClose} />
                    <Tooltip anchorSelect="#aForgotPass">
                      Don't worry! Click here to get verification code by an email.
                    </Tooltip>
                  </div>

                  <div className="error-msg">{showerror}</div>
                  <div className="login-btns">
                    <Button onClick={() => ValidateLogin(newdata.email, newdata.password)}>Sign In</Button>
                    <p>
                      Don’t have an account?{" "}
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          setShowSignIn(false);
                          setShowSignUp(true);
                        }}
                      >
                        Sign Up
                      </a>
                    </p>
                  </div>
                </form>
                {/* <div className="login-bottom-menus"> */}
                  {/* <p>© 2023 MyCloud POS</p> */}
                  {/* <ul>
                    <li>
                      <a href="">Privacy</a>
                    </li>
                    <li>
                      <a href="">Legal</a>
                    </li>
                    <li>
                      <a href="">Contact</a>
                    </li>
                  </ul> */}
                {/* </div> */}
              </div>
            )}

            {showsignup && (
              <div id="divSignUp">
                <div className="login-logo-img">
                  <img src={loginlogo} className="img-fluid"></img>
                </div>
                <div className="login-head">
                  <h2>Sign Up</h2>
                  <p>Please fill details below to continue</p>
                </div>

                <form onSubmit={SignUp}>
                  <Input
                    label="Full Name"
                    placeholder="Enter full name"
                    name="fullname"
                    value={inputs.fullname}
                    onChange={onChange}
                    required="true"
                  />
                  <Input
                    label="Email Address"
                    placeholder="Enter email address"
                    name="signupemail"
                    value={inputs.signupemail}
                    onChange={onChange}
                    required="true"
                    type="email"
                  />
                  <Input
                    label="Password"
                    placeholder="Enter password"
                    name="signuppwd"
                    value={inputs.signuppwd}
                    onChange={onChange}
                    required="true"
                    type="password"
                  />
                  {/* <Input
                    label="Phone Number"
                    placeholder="Enter phone number..."
                    name="phone"
                    value={showphonevalid}
                    onChange={onChange}
                    required="true"
                  /> */}

                    <div className="ph-num-field phNumber">
                      <label>
                        Phone number</label>
                      <PhoneInput
                        country={'us'}
                        // value={PhoneNumber}
                        // value={showphonevalid}
                        value={newNO?.split('+')[1]?.split(' ')[0] + "" + newNO?.split('+')[1]?.split(' ')[1]}
                        onChange={(value, country, event, formattedValue) => {setnewNo("+" + country.dialCode + " " + value.slice(country.dialCode.length)); }}
                        inputProps={{ required: true }}
                      ></PhoneInput>
                    </div>
                  {/* <label className="phoneLabel">Phone</label> */}
                  <div className="popup-phone">

                    {/* <ReactIntlTelInput
                        intlTelOpts={{ preferredCountries: ['us'], }}
                        value={countryCode}
                        name="phone"
                        onChange={async (phone) => { setCountryCode(phone); }}
                      /> */}
                    {/* <PhoneInput
                      autoFormat
                      country={"in"}
                      enableSearch={true}
                      disableSearchIcon={true}
                      preferredCountries={["in", "us"]}
                      inputClass="inp-cls"
                      containerClass="cust"
                      dropdownClass="dropd"
                      placeholder="Enter phone number"
                      countryCodeEditable={false}
                      onChange={(v, e, ee) => {
                        setPhoneNumber(v)
                        setCountryCode(e)
                      }}
                    /> */}
                    {/* <span className="countryCode" >+{countryCode?.dialCode}</span>
                    <input
                      type="tel"
                      name="phoneNumber"
                      className="form-control"
                      required
                      value={phoneNumber}
                      onChange={async (e) => { setPhoneNumber(e.target.value) }}
                      onFocus={() => { }}
                    /> */}
                  </div>
                  <Input
                    label="Company Name"
                    placeholder="Enter company name"
                    name="companyname"
                    value={inputs.companyname}
                    onChange={onChange}
                    required="true"
                  />
                  <TextArea
                    label="Message"
                    rows={3}
                    name="message"
                    onChange={onChange}
                    value={inputs.message}
                    required="true"
                  ></TextArea>
                  {/* <div className="error-msg">{showsignuperror}</div> */}
                  <div className="login-btns">
                    <Button type="submit">Sign Up</Button>
                    <p>
                      Already have an account?{" "}
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          setShowSignIn(true);
                          setShowSignUp(false);
                        }}
                      >
                        Sign In Here
                      </a>
                    </p>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
